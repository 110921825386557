import React, { useState, useEffect } from "react";

import Header from "./sections/Header";
import Intro from "./sections/Intro";
import Network from "./sections/Network";
import Discord from "./sections/Discord";
import Socials from "./sections/Socials";

import Tokenomics from "./sections/Tokenomics";
import NFTs from "./sections/NFTs";

const MainPage = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [fontsLoaded, setFontsLoaded] = useState(true);

  // useEffect(() => {
  //   const checkFontLoad = async () => {
  //     await document.fonts.ready;
  //     setFontsLoaded(true);
  //   };
  //   checkFontLoad();
  // }, []);

  return (
    <>
      {(!fontsLoaded || !imagesLoaded) && <div className="main-loading"></div>}
      <div className="main-page">
        <Header
          setImagesLoaded={setImagesLoaded}
          isLoaded={fontsLoaded && imagesLoaded}
        />
        <Intro />
        <Network />
        <NFTs />
        <Discord />
        <Tokenomics />
        <Socials />
      </div>
    </>
  );
};

export default MainPage;
