import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const NFTs = () => {
  return (
    <>
      <div className="content-box nfts">
        <div className="header">
          <div className="title">BEAR NFTs</div>

          <div className="text">
            The BEAR community has collectible NFTs for profile pictures. The{" "}
            <span>Ultra Rare Collection</span> and the{" "}
            <span>Pixel Collection</span>.
          </div>
        </div>

        <div className="content">
          <div className="collection">
            <div className="label">Ultra Rare BEARs</div>

            <div className="text">
              The BEAR Ultra Rare Collection was the first mint and consists
              of&nbsp;668&nbsp;NFTs
            </div>
            <div className="image">
              <img src="images/nfts/BEAR-1.png" alt="" />
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="button"
              layout="position"
            >
              <Link
                className="link"
                to="https://xrp.cafe/collection/bearxrpl"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ultra Collection
              </Link>
            </motion.button>
          </div>
          <div className="collection">
            <div className="label">Pixel BEARs</div>
            <div className="text">
              There are 5890 Pixel Bears to provide a profile picture for
              every&nbsp;Bear&nbsp;Maxi
            </div>
            <div className="image">
              <img src="images/nfts/BEAR-PIXEL.webp" alt="" />
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="button"
              layout="position"
            >
              <Link
                className="link"
                to="https://xrp.cafe/collection/bear-pixel-collection"
                target="_blank"
                rel="noopener noreferrer"
              >
                Pixel Collection
              </Link>
            </motion.button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NFTs;
