exports.DAILY_REWARDS_NFT = 110000;
exports.DAILY_REWARDS_LP = 220000;

exports.BEAR_NFT_ISSUER = "rBEARbo4Prn33894evmvYcAf9yAQjp4VJF";
exports.BEAR_BURNED_LP = "rBEARmPLNA8CMu92P4vj95fkyCt1N4jrNm";

exports.BEAR_LP_ISSUER = "rwE86ARLXfyKYCVmFpk511ddYfs5Fh6Vcp";
exports.BEAR_LP_CURRENCY = "0359B4F3702A9F14FD28BE6D595D1CDF12845973";

exports.BEAR_ISSUER = "rBEARGUAsyu7tUw53rufQzFdWmJHpJEqFW";
exports.BEAR_CURRENCY = "4245415200000000000000000000000000000000";
