import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { BEAR_ISSUER, BEAR_CURRENCY } from "config/index";
import { getAccountLinesParsed } from "utils/getAccountLinesParsed";
import { getXrplClient } from "utils/getXrplClient";

const consoleLog = true;

const Tokenomics = () => {
  const [supply, setSupply] = useState("");

  useEffect(() => {
    //
    const getSupplyData = async () => {
      //
      const xrplClient = await getXrplClient({ consoleLog });
      if (xrplClient?.error) return xrplClient;

      const accountLines = await getAccountLinesParsed({
        consoleLog,
        xrplClient,
        account: BEAR_ISSUER,
      });

      if (accountLines?.error) return accountLines;
      if (
        !accountLines?.totalHolderBalances ||
        !Array.isArray(accountLines.totalHolderBalances)
      ) {
        return;
      }

      const supplyData = accountLines.totalHolderBalances.find(
        (hold) => hold.issuer === BEAR_ISSUER && hold.currency === BEAR_CURRENCY
      );
      if (supplyData?.balance) {
        setSupply(
          Number(supplyData.balance.toFixed(0)).toLocaleString("en-US")
        );
      }
    };

    if (!supply) getSupplyData();
  }, []);
  return (
    <>
      <div className="content-box tokenomics">
        <div className="header">
          <div className="title">Tokenomics</div>

          <div className="text">
            $BEAR made <span>589,000,000 tokens</span> and blackholed the issuer
            wallet. Then he <span>airdropped 90%</span> of the supply to the
            community and <span>burned the remaining 10%</span>
          </div>
        </div>

        <div className="content">
          <div className="image-box">
            <div className="image">
              <img src="images/bear/traderbear.jpeg" alt="" />
            </div>
          </div>
          <div className="stats">
            <div className="stat">
              <div className="label">Initial Supply</div>
              <div className="value">589,000,000 $BEAR</div>
            </div>
            <div className="stat">
              <div className="label">Current Supply</div>
              <div className="value">
                {supply ? `${supply} $BEAR` : "Loading"}
              </div>
            </div>
            <div className="stat address">
              <div className="label">Issuer Address</div>
              <motion.div
                className="value"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.2 }}
                layout="position"
              >
                <Link
                  className="link"
                  to="https://bithomp.com/explorer/rBEARGUAsyu7tUw53rufQzFdWmJHpJEqFW"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  rBEARGUAsyu7tUw53rufQzFdWmJHpJEqFW
                </Link>
              </motion.div>
            </div>
            {/* <motion.button
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="button"
              layout="position"
            >
              <Link
                className="link"
                to="https://xrpl.services/?issuer=rBEARGUAsyu7tUw53rufQzFdWmJHpJEqFW&currency=BEAR&limit=589000000"
                target="_blank"
                rel="noopener noreferrer"
              >
                Set $BEAR Trustline
              </Link>
            </motion.button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Tokenomics;
