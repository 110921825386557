import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Intro = () => {
  return (
    <>
      <div className="content-box intro">
        <div className="header">
          {/* <div className="title">$BEAR</div> */}
          <div className="text">
            <p className="p1">
              $BEAR is a memecoin in tribute to the most{" "}
              <span>famous XRP&nbsp;meme</span> bearableguy123
            </p>
            <p>
              Since 2018 <span>bearableguy</span> posted riddles in images with
              a masked&nbsp;BEAR:
            </p>
          </div>
        </div>

        <div className="content">
          <div className="image-box">
            <div className="image">
              <img src="images/bear/riddle.jpeg" alt="" />
            </div>
          </div>

          <div className="stats">
            <div className="text">
              The community decrypted these riddles and found hints like{" "}
              <span>"589"</span> which became an integral part of XRP&nbsp;lore
            </div>

            {/* <motion.button
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="button"
              layout="position"
            >
              <Link
                className="link"
                to="https://xmagnetic.org/dex/BEAR+rBEARGUAsyu7tUw53rufQzFdWmJHpJEqFW_XRP+XRP?network=mainnet"
                target="_blank"
                rel="noopener noreferrer"
              >
                Buy $BEAR
              </Link>
            </motion.button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Intro;
